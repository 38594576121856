import TagManager from "react-gtm-module";

export const getUrlParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const onlyNumbers = (e) => {
  const num = /[^0-9]/gi;
  e.target.value = e.target.value.replace(num, "");
};

export const onlyAlphabet = (e) => {
  const alphabets = /[^A-Za-z]+$/;
  e.target.value = e.target.value.replace(alphabets, "");
};

export const pushEvent = (eventName, eventData) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData,
    },
  });
};
