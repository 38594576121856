import React, { useCallback, useEffect, useState } from "react";
import { Notify } from "@flexisaf/flexibull2";
import httpClient from "../utils/http-client";

const useValidateSchool = ({ tenantId }) => {
  const [schoolExists, setSchoolExists] = useState(false);
  const [loading, setLoading] = useState(false)

  const validateSchool = useCallback(async () => {
    setLoading(true)
    try {
      const params = {
        "tenant-id": tenantId,
      };
      const { data } = await httpClient.get(`/school-info/validate`, {
        params,
      });
      setSchoolExists(data);
      setLoading(false)
      return { success: true, response: data }
    } catch (error) {
      Notify("Something went wrong!", { status: "error" });
      setLoading(false)
      return { success: false, response: {} }
    }
  }, [tenantId]);

  useEffect(() => {
    validateSchool();
  }, [validateSchool, tenantId]);

  return {
    validateSchool,
    schoolExists,
    setSchoolExists,
    laodingValidation: loading
  };
};

export default useValidateSchool;
