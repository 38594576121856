import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Spacer,
  Notify,
  Modal,
  ModalBody,
  Input,
  ModalClose,
} from "@flexisaf/flexibull2";
import bgImg from "./assets/singleBg.svg";
import { Theme } from "./config/theme";
import httpClient from "./utils/http-client";
import logo from "./assets/logo.svg";
import { pushEvent } from "./utils/utils";
import useResendLink from "./hooks/useResendLink";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import OTPSVG from "./assets/otp.svg";
import useVerifyOtp from "./hooks/useVerifyOtp";
import useBreakpointValue from "./utils/useBreakpointValue/useBreakpointValue";
import config from "./utils/config";

const { SCHOOL_URL } = config;

const OTPDiv = styled.div`
  margin: 30px 0;
  /* width: 80%; */
`;

const MailSent = ({ url, umsResponse }) => {
  const [code, setCode] = useState("");

  const email = umsResponse?.user?.username

  const redirectToSafsims = () => {
    pushEvent("New_School_Signup_OTP_Skipped", {
      user_id: umsResponse?.user.id,
      organisation: url.url_prefix,
      user_role: "ONBOARDING",
    });
    const link = `http://${url.url_prefix}${SCHOOL_URL}?id=${umsResponse?.user.id}&staffId=${umsResponse?.staffId}&at=${umsResponse?.access_token}&rt=${umsResponse?.refresh_token}`;
    localStorage.clear();
    window.location.href = link
  };

  const { loading, verifyOtp } = useVerifyOtp({
    url_prefix: url?.url_prefix,
    callback: () => {
      setTimeout(redirectToSafsims, 300);
    },
  });

  const { resendLink, loading: resendingLink } = useResendLink({
    tenant: url.url_prefix,
  });


  
  const width = useBreakpointValue({
    base: '97%',
    md: '500px'
  })
  

  return (
    <Modal open={true}>
      <ModalBody width={width}>
        <Box display="flex" pad="30px" style={{ alignItems: 'center'}}>
          <Box width="50%">
            <img src={logo} alt="SAFSIMS logo" height="30" />
          </Box>
          <Box  width="50%" align="right" >
            <Button
              pale
              height={32}
              color="rgba(0, 0, 0, 0)"
              fontColor={Theme.PrimaryFontColor}
              spaceLeft
              onClick={redirectToSafsims}
            >
              <Text size="12px"> Skip verification </Text>
            </Button>
          </Box>
        </Box>
        <>
          <Box pad="60px 50px 0 50px">
            <Box align="center" pad="20px 0 0 0">
              <img src={OTPSVG} height="100px" />
            </Box>
            <Box align="center">
              <h3>Verify OTP</h3>
              <Text style={{ lineHeight: "18px" }}>
                An OTP code has been sent to <b>{email}</b>'s inbox. Also
                check your spam/junk folder if you don't receive the code.
              </Text>
            </Box>
          </Box>
          <OTPDiv pad="30px 10px">
            <Box vAlign display="flex" margin="auto" width="80%" height="50px" style={{ justifyContent: 'center' }}>
            <OtpInput
              value={code}
              onChange={(data) => setCode(data)}
              numInputs={6}
              inputStyle={{
                width: "2.5rem",
                height: "2.5rem",
                margin: "0 0.3rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
            />
            </Box>
          </OTPDiv>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button
              spaceLeft
              // iconRight={<i className="flexibull-angle-right" />}
              progress={loading}
              onClick={() => {
                verifyOtp({
                  token: code,
                  user_id: umsResponse?.user?.id,
                })
                pushEvent("New_School_Signup_OTP_Submitted", {
                  user_id: umsResponse?.user.id,
                  organisation: url.url_prefix,
                  user_role: "ONBOARDING",
                });
              }
              }
              style={{ width: '60%' }}
            >
              <Text size="14px">Submit</Text>
            </Button>
          </Box>

          <Box pad="60px 50px 30px 50px">
            <Box align="center">
            <Text size="13px" >
              Haven’t received the mail yet?
            </Text>
            <Button
              pale
              height={20}
              width={102}
              color="rgba(0, 0, 0, 0)"
              onClick={() => resendLink(email)}
              progress={resendingLink}
            >
              <Text><u>Resend Link</u></Text>
            </Button>
            </Box>
            <Spacer space="30px" />
          </Box>
        </>
      </ModalBody>
    </Modal>
  );
};

export default MailSent;
