import React from 'react';
import { Box, Button, Modal, ModalBody, ModalClose, ModalFooter, Text } from '@flexisaf/flexibull2';

import useBreakpointValue from '../../../utils/useBreakpointValue/useBreakpointValue';
import { Theme } from '../../../config/theme';

export const TermsAndCondition = ({ open, onClose, isMadrasa }) => {
  const width = useBreakpointValue({ base: '90%', md: '50vw' });
  const safsimsNameDefinition = isMadrasa ? 'MADRASA SIMS' : 'SAFSIMS';

  return (
    <Modal onClose={onClose} open={open}>
      <ModalBody width={width}>
        <ModalClose onClick={onClose}>&times;</ModalClose>
        <Box pad="20px">
          <Text bold size="20px">
            Terms And Condition
          </Text>
        </Box>
        <Box
          pad="20px"
          style={{ maxHeight: '70vh', overflowY: 'scroll', width: '100%', lineHeight: '17px' }}
        >
          <Text>
            <p>
              <b>1. Introduction</b> <br />
              These Terms and Conditions ("Terms") govern your access to and use of the{' '}
              {safsimsNameDefinition}
              platform ("Platform"), including its website, web application, and mobile applications
              for teachers and parents (collectively, "Applications"), provided by FlexiSAF Edusoft
              Limited ("FlexiSAF"). By accessing or using the Platform, you agree to be bound by
              these Terms. If you do not agree to all of the Terms, you are not authorized to use
              the Platform. <br />
              By accessing or using {safsimsNameDefinition}, you acknowledge that you have read,
              understood, and agree to be bound by these Terms.
            </p>

            <p>
              <b>2. Definitionsn</b> <br />
              <ul>
                <li>
                  <b>Platform:</b> Refers to the {safsimsNameDefinition} website, web application,
                  and mobile applications for teachers and parents.
                </li>
                <li>
                  <b>User:</b> Refers to any individual or entity authorized to access and use the
                  Platform, including:
                  <ul>
                    <li>
                      <b>School Owner:</b> The entity subscribing to {safsimsNameDefinition} on
                      behalf of the school.
                    </li>
                    <li>
                      <b>School Administrator:</b> The entity subscribing to {safsimsNameDefinition}{' '}
                      on behalf of the school.
                    </li>
                    <li>
                      <b>Teacher:</b> The entity subscribing to {safsimsNameDefinition} on behalf of
                      the school.
                    </li>
                    <li>
                      <b>Parent:</b> The entity subscribing to {safsimsNameDefinition} on behalf of
                      the school.
                    </li>
                    <li>
                      <b>Student:</b> The entity subscribing to {safsimsNameDefinition} on behalf of
                      the school.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Content:</b> Refers to any data, information, or materials uploaded, submitted,
                  or stored within the Platform by Users.
                </li>
              </ul>
            </p>
            <p>
              <b>3. Access and Use</b>
            </p>
            <p>
              <b>3.1 Eligibility:</b>
              <ul>
                <li>School Owners must be legally authorized representatives of the school.</li>
                <li>School Administrators, Teachers, and Parents must be at least 18 years old.</li>
                <li>
                  Students must be of an age appropriate for using the Platform under the direct
                  supervision of Teachers and Parents.
                </li>
              </ul>
            </p>
            <p>
              <b>3.2 Account Creation:</b>
              <ul>
                <li>School Owners create an account to subscribe to {safsimsNameDefinition}.</li>
                <li>
                  School Administrators, Teachers, and Parents are required to create individual
                  accounts for access granted by the School Owner or Administrator.
                </li>
                <li>
                  Users are responsible to provide accurate, current, and complete information when
                  creating their accounts, and to update the information promptly if it changes.
                </li>
                <li>
                  Users are responsible for maintaining the confidentiality of their account
                  information and for all activities that occur under their accounts.
                </li>
                <li>
                  Users are to notify their school or FlexiSAF immediately of any unauthorized use
                  of their account or any other breach of security.
                </li>
              </ul>
            </p>
            <p>
              <b>3.3 Acceptable Use:</b>
              <ul>
                <li>
                  You agree to use the Platform in a lawful manner and in accordance with all
                  applicable laws and regulations.
                </li>
                <li>
                  You will not use the Platform for any purpose that is prohibited by these Terms or
                  that is harmful to the Platform or others.
                </li>
              </ul>
            </p>

            <p>
              <b>4. Content</b>
            </p>
            <p>
              <b>4.1 User Responsibility:</b>
              <ul>
                <li>
                  Each User is solely responsible for all Content they upload, submit, or store
                  within the Platform.
                </li>
                <li>
                  Users represent and warrant that they have all necessary rights and permissions to
                  use, distribute, and share such Content.
                </li>
              </ul>
            </p>
            <p>
              <b>4.2 Company Ownership:</b>
              <ul>
                <li>
                  FlexiSAF claims no ownership rights over your Content, but you grant FlexiSAF a
                  non-exclusive, royalty-free license to use, reproduce, modify, publish,
                  distribute, and translate your Content solely for the purpose of providing and
                  improving the Platform.
                </li>
              </ul>
            </p>

            <p>
              <b>4.3 Prohibited Conduct:</b>
              <br />
              You agree not to:
              <ul>
                <li>
                  Use {safsimsNameDefinition} for any unlawful purpose or in any way that violates
                  these Terms.
                </li>
                <li>
                  Use {safsimsNameDefinition} to impersonate any person or entity, or to falsely
                  state or otherwise misrepresent your affiliation with any person or entity.
                </li>
                <li>
                  Interfere with or disrupt the operation of {safsimsNameDefinition} or the servers
                  or networks used to make {safsimsNameDefinition} available.
                </li>
                <li>
                  Transmit any viruses, worms, defects, Trojan horses, or any other items of a
                  destructive nature through {safsimsNameDefinition}.
                </li>
                <li>
                  Use any robot, spider, scraper, or other automated means to access{' '}
                  {safsimsNameDefinition} for any purpose without our express written permission.
                </li>
              </ul>
            </p>
            <p>
              <b>5. Intellectual Property Rights</b> <br />
              The Platform and all intellectual property rights associated with it, including
              trademarks, copyrights, and patents, are the exclusive property of FlexiSAF. You are
              granted a non-exclusive, non-transferable license to use the Platform in accordance
              with these Terms. You may not modify, reproduce, distribute, create derivative works
              of, publicly display, publicly perform, republish, download, store, or transmit any
              content of {safsimsNameDefinition} without the prior written consent of FlexiSAF.
            </p>

            <p>
              <b>6. Fees and Payment</b>
            </p>
            <p>
              <b>6.1 Subscription Fees:</b>
              <ul>
                <li>
                  School Owners agree to pay the applicable subscription fees for their chosen plan.
                </li>
                <li>
                  Parents agree to pay any transaction related charges while paying school fees
                  through our payment gateway partners.
                </li>
              </ul>
            </p>
            <p>
              <b>6.2 Payment Methods:</b>
              <ul>
                <li>
                  FlexiSAF offers various payment methods, which may be subject to additional terms
                  and conditions.
                </li>
              </ul>
            </p>
            <p>
              <b>6.3 Refund Policy:</b>
              <ul>
                <li>
                  FlexiSAF offers free trial for new subscribers, however, once the trial elapses,
                  FlexiSAF does not offer a refund once subscription has been paid.
                </li>
              </ul>
            </p>

            <p>
              <b>7. Termination</b> <br />
              Users may terminate their access by contacting FlexiSAF. All provisions of these Terms
              which by their nature should survive termination shall survive termination.
            </p>
            <p>
              <b>8. Limitation of Liability</b> <br />
              To the fullest extent permitted by applicable law, FlexiSAF shall not be liable for
              any direct, indirect, incidental, special, consequential, or exemplary damages,
              including but not limited to damages for loss of profits, goodwill, use, data, or
              other intangible losses arising out of or in connection with your use of or inability
              to use {safsimsNameDefinition}.
            </p>
            <p>
              <b>9. Disclaimer of Warranties</b> <br />
              THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND,
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. FLEXISAF DOES NOT WARRANT THAT
              THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE.
            </p>
            <p>
              <b>10. Modifications to Terms</b> <br />
              FlexiSAF reserves the right to modify or update these Terms at any time, with or
              without prior notice.
              <br />
              Any changes to these Terms will be effective immediately upon posting on the{' '}
              {safsimsNameDefinition}
              website or within the {safsimsNameDefinition} application.
            </p>
          </Text>
        </Box>

        <ModalFooter>
          <Box pad="20px" style={{ display: 'flex' }}>
            <Button
              style={{ margin: '0 0 0 auto' }}
              onClick={onClose}
              plain
              fontColor={Theme.PrimaryFontColor}
            >
              Close
            </Button>
            {/* <Button>Accept</Button> */}
          </Box>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
