import {
  Notify,
} from "@flexisaf/flexibull2";
import { useState } from 'react';
import httpClient from "../utils/http-client";
import { pushEvent } from "../utils/utils";

const useCreateSchool = ({ callback }) => {
    const [loading, setIsLoading] = useState(false);
    
  const createSchool = async (data) => {
    setIsLoading(true);
    try {
      const headers = {
        "X-TENANT-ID": data.url_prefix,
      };
      const { data: res } = await httpClient.post(`/signup`, data, { headers });
      pushEvent("New_School_Signup_Submitted", {
        user_id: res?.staff.id,
        organisation: data.url_prefix,
        user_role: "ONBOARDING",
      });
      Notify("School created. Enter the OTP that was sent to your email.");
      setIsLoading(false);
      callback({...res.ums_login_response, staffId: res.staff.id});
      localStorage.setItem("umsResponse", JSON.stringify({...res.ums_login_response, staffId: res.staff.id}))
    } catch (error) {
      const errorMessage =
        error.response.status === 409
          ? `School with short name ${data.url_prefix} already exists`
          : "Something went wrong!";
      Notify(errorMessage, { status: "error" });
      setIsLoading(false);
    }
  };



  return {
    loading,
    createSchool
  }
}

export default useCreateSchool