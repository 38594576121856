import React from "react";
import { Notify } from "@flexisaf/flexibull2";
import httpClient from "../utils/http-client";
import useLoading from "../utils/useLoading/useLoading";

const useResendLink = ({ tenant }) => {
  const { loading, startLoading, stopLoading } = useLoading();

  const resendLink = async (username) => {
    startLoading();
    try {
      const headers = {
        "X-TENANT-ID": tenant,
      };
      await httpClient.post(
        "/auth/resend-verification",
        { username, verification_type: "PINCODE" },
        { headers }
      );
      stopLoading();
      Notify("OTP sent successfully.");
    } catch (error) {
      stopLoading();
      Notify("An error occurred. Please check the code.", { status: "error" });
    }
  };

  return {
    resendLink,
    loading,
  };
};

export default useResendLink;
