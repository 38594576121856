import { useState } from 'react';
import styled from 'styled-components';
import { createForm } from 'rc-form';
import {
  Input,
  Box,
  Text,
  Spacer,
  Select,
  Button,
  RadioButton,
  Checkbox,
  Grid,
  Notify
} from '@flexisaf/flexibull2';

import PhoneNumberInput from '../../components/PhoneInput';
import usePasswordPolicy from '../../hooks/usePasswordPolicy';
import { Theme } from '../../config/theme';
import { TermsAndCondition } from './modals/termsAndCondition';
import { PrivacyPolicy } from './modals/privacyPolicy';

const DEFAULT_ROLE = [
  { label: 'School Owner', value: 'School Owner' },
  { label: 'Director', value: 'Director' },
  { label: 'Principal/Head Teacher', value: 'Principal/Head Teacher' },
  { label: 'ICT Admin', value: 'ICT Admin' },
  { label: 'Teacher', value: 'Teacher' },
  { label: 'Others', value: 'Others' }
];

const Error = styled.em`
  display: block;
  margin-top: 4px;
`;

const Step2 = ({ form, data, goBack, onFinish, loading, isMadrasa }) => {
  const { getFieldError, getFieldProps, getFieldValue, validateFields } = form;

  const [openTermsAndCondition, setOpenTermsAndCondition] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [read, setRead] = useState(false)

  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');

  const [viewPassword, setViewPassword] = useState(false);

  const {
    handlePasswordPolicy,
    moreThan8chars,
    lowerCaseAndUppercase,
    oneNumber,
    specialCharacter,
    hasResolvedPassword
  } = usePasswordPolicy();

  const onSubmit = async () => {
    validateFields((err, values) => {
      if (!err) {
        if (!gender) {
          return Notify('Please select a gender', { status: 'info' });
        }
        if (!phone || (phone?.length || 0) < 6) {
          return Notify('Please enter a valid phone number', {
            status: 'info'
          });
        }
        if (!hasResolvedPassword) {
          return Notify('Please enter a valid password that matches all requirements.', {
            status: 'info'
          });
        }
        let payload = {
          acquisition_type: isMadrasa ? 'MADRASA' : 'PLG',
          first_name: values.first_name,
          last_name: values.last_name,
          email_address: values.email_address,
          phone: phone,
          gender: gender,
          role_in_school: values.role_select?.value,
          password: values.password,
          school_name: data?.school_name,
          short_name: data?.url_prefix,
          url_prefix: data?.url_prefix,
          website: data?.school_website,
          country: {
            country_code: data?.country?.value, iso3: data?.country?.iso3, name: data?.country?.label
          }
        };

        onFinish(payload);
      } else {
        Notify('Please fill all fields', { status: 'info' });
      }
    });
  };

  const isOthers = getFieldValue('role_select')?.value === 'Others';
  const schoolNameDefinition = isMadrasa ? 'Madrasa' : 'School'

  return (
    <Box pad="10px 0">
      <Box pad="10px 0" align="left">
        <Text bold>About you</Text>
        <Spacer />
        <Text>A bit of information about the {schoolNameDefinition} you work and represent. </Text>
      </Box>
      <Grid default="repeat(2, 1fr)" md="repeat(2, 1fr)" sm="repeat(2, 1fr)">
        <Box pad="30px 0 10px 0">
          <Input
            label="First Name"
            placeholder="Enter first name..."
            required
            block
            error={getFieldError('first_name') ? 'First Name is required' : null}
            {...getFieldProps('first_name', {
              initialValue: '',
              rules: [{ required: true }]
            })}
          />
        </Box>
        <Box pad="30px 0 10px 0">
          <Input
            label="Last Name"
            placeholder="Enter last name..."
            required
            block
            error={getFieldError('last_name') ? 'Last Name is required' : null}
            {...getFieldProps('last_name', {
              initialValue: '',
              rules: [{ required: true }]
            })}
          />
        </Box>
      </Grid>
      <Box pad="10px 0 10px 0" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Text bold size="0.9em">
          GENDER
        </Text>
        <RadioButton
          name="gender"
          label="Male"
          elevate="high"
          checked={gender === 'MALE'}
          onClick={() => setGender('MALE')}
        />
        <RadioButton
          name="gender"
          label="Female"
          elevate="high"
          checked={gender === 'FEMALE'}
          onClick={() => setGender('FEMALE')}
        />
      </Box>
      <Box pad="30px 0 10px 0">
        <Input
          label="Email Address"
          placeholder="E.g admin@schoolname.com ..."
          required
          block
          error={getFieldError('email_address') ? 'Email is required' : null}
          {...getFieldProps('email_address', {
            initialValue: '',
            rules: [{ required: true }]
          })}
        />
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <PhoneNumberInput
          width="100%"
          label="Phone Number"
          value={phone}
          name="phone number"
          onValueChange={setPhone}
          defaultCountry={data?.country}
          required
        />
      </Box>
      <Box pad="10px 0">
        <Select
          placeholder="Select Your Role"
          elevate={true}
          name="role"
          label="role"
          spaceTop
          spaceBottom="10px"
          required
          options={DEFAULT_ROLE}
          style={{
            width: '100%',
            fontSize: '1em'
          }}
          error={getFieldError('role_select') ? 'Role is required' : null}
          {...getFieldProps('role_select', {
            initialValue: '',
            rules: [{ required: true }]
            // onChange: (e) => handleRoleSelect(e)
          })}
        />
      </Box>
      {isOthers ? (
        <Box pad="5px 0">
          <Input
            placeholder="e.g Admin"
            label="Enter Your Role"
            spaceTop
            spaceBottom="15px"
            required
            style={{
              width: '100%'
            }}
            error={getFieldError('role') ? 'Role is required' : null}
            {...getFieldProps('role', {
              initialValue: '',
              rules: [{ required: isOthers }]
            })}
          />
        </Box>
      ) : null}
      <Box>
        <Input
          placeholder="Enter your password"
          type={viewPassword ? 'text' : 'password'}
          label="Password"
          spaceTop
          required
          error={getFieldError('password') ? 'Password address is required' : null}
          block
          {...getFieldProps('password', {
            initialValue: '',
            type: 'password',
            onChange: (e) => handlePasswordPolicy(e),
            rules: [{ required: true }]
          })}
        />
        <Box align="right" margin="2px 0">
          <span onClick={() => setViewPassword(!viewPassword)}>
            <Checkbox label="View password" elevate="high" size="15px" checked={viewPassword} />
          </span>
        </Box>
      </Box>
      <Box>
        <Error className="error">Password must contain</Error>
        <div className="center">
          <ul>
            <li className={moreThan8chars ? 'done' : ''}>Minimum of 8 characters</li>
            <li className={lowerCaseAndUppercase ? 'done' : ''}>
              One uppercase letter and One lowercase
            </li>
            <li className={oneNumber ? 'done' : ''}>One number</li>
            <li className={specialCharacter ? 'done' : ''}>One special character</li>
          </ul>
        </div>
      </Box>
      <Box pad='10px 0'>
        <Checkbox checked={read} onClick={() => setRead(!read)} label={<Text>
          I agree and consent to the <span onClick={() => setOpenTermsAndCondition(true)} style={{ color: Theme.PrimaryColor, cursor: "pointer", textDecoration: 'underline' }}>terms & condition</span> and <span onClick={() => setOpenPrivacyPolicy(true)} style={{ color: Theme.PrimaryColor, cursor: "pointer", textDecoration: 'underline' }}>privacy policy</span>
        </Text>} elevate="high" size="15px" />
      </Box>
      <Box style={{ display: 'flex' }}>
        <Button pale onClick={() => goBack()}>
          Previous
        </Button>
        <Button loading={loading} disabled={loading || !read} style={{ marginLeft: 'auto' }} onClick={() => onSubmit()}>
          Submit
        </Button>
      </Box>

      <TermsAndCondition open={openTermsAndCondition} onClose={() => setOpenTermsAndCondition(false)} isMadrasa={isMadrasa} />
      <PrivacyPolicy open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} isMadrasa={isMadrasa} />
    </Box>
  );
};

export default createForm()(Step2);
