import { useState, useEffect } from 'react';
import { createForm } from 'rc-form';

import { Input, Box, Text, Spacer, Select, Button, Notify } from '@flexisaf/flexibull2';
import { lighten } from 'polished';
import { Theme } from '../../config/theme';

import useValidateSchool from '../../hooks/useValidateSchool';
import httpClient from '../../utils/http-client';

let format = /[ `§±!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

// let validationDouncer;

const Step1 = ({ form, nextStep, data, isMadrasa }) => {
  const { getFieldError, getFieldProps, getFieldValue, validateFields } = form;
  const [countries, setCountries] = useState([]);
  const [urls, setUrls] = useState([]);
  const [schoolUrl, setSchoolUrl] = useState('');

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await httpClient.get(`/countries`);
        setCountries(response.data);
      } catch (error) {
        // setError(error);
        return;
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchUrlPrefixes = async () => {
      try {
        const response = await httpClient.get(`/signup/url-prefixes`);
        setUrls(response.data.url_prefixes);
      } catch (error) {
        return;
      }
    };
    fetchUrlPrefixes();
  }, []);

  const { schoolExists, setSchoolExists, validateSchool } = useValidateSchool({
    tenantId: schoolUrl
  });

  const validateUrl = async (e) => {
    e.stopPropagation();
    setSchoolUrl(e.target.value);
    await validateSchool(getFieldValue('url_prefix'));
  };

  useEffect(() => {
    if (data?.url_prefix) {
      validateSchool(data?.url_prefix);
    }
  }, [data?.url_prefix, validateSchool])

  const handleUrl = (e) => {
    if (urls.length > 0 && urls.find((url) => url.toLowerCase() === getFieldValue('short_name'))) {
      Notify('This school short name already exists', { status: 'info' });
    } else if (format.test(e)) {
      Notify('School short name must contain only numbers, letters and underscores', { status: 'info' });
    } else {
      setSchoolExists(false);
    }
  };

  const url_prefix = getFieldValue('url_prefix')?.toLowerCase() ?? '';

  const onSubmit = async () => {
    validateFields((err, values) => {
      if (!err) {
        if (schoolExists) {
          return Notify('School Short name already exists', {
            status: 'info'
          });
        }
        nextStep({ ...values, url_prefix: values?.url_prefix?.toLowerCase() })
      }
    })
  }

  const urlPrefixError = getFieldError('url_prefix') ? (schoolExists ? 'School short name already exists' : 'School short name is required') : '';
  const schoolNameDefinition = isMadrasa ? 'Madrasa' : 'School'

  return (
    <Box pad="10px 0"><Box
      margin="10px 0"
      pad="15px"
      style={{
        display: 'flex',
        gap: '10px',
        borderRadius: Theme.PrimaryRadius,
        background: '#f0f0f0',
        alignItems: 'center',
      }}
    >
      <i className="saf-magic-star" style={{ color: 'gold', fontSize: '16px' }} />
      <Box>
        <Text size="14px" bold>
          What is Short Name?
        </Text>
        <br />
        <Text size="11px">
          Short name is an abbreviation of your {schoolNameDefinition} name used as a unqiue identifier for parents, teachers & students to access your platform.
        </Text>
      </Box>
    </Box>
      <Box pad="10px 0" align="left">
        <Text bold>About the {schoolNameDefinition}</Text>
        <Spacer />
        <Text>A bit of information about the {schoolNameDefinition} you work and represent. </Text>
      </Box>
      <Box pad="40px 0 10px 0">
        <Input label={`${schoolNameDefinition} Name`} placeholder={`Enter ${schoolNameDefinition} Name...`} required block
          error={getFieldError('school_name') ? 'School name is required' : null}
          {...getFieldProps('school_name', {
            initialValue: data ? data?.school_name : '',
            rules: [{ required: true }]
          })} />
      </Box>
      <Box pad="40px 0 5px 0">
        <Input
          label={`${schoolNameDefinition} Short Name`}
          placeholder={`Enter ${schoolNameDefinition} Short Name...`}
          required
          block
          // error={(getFieldError('url_prefix') ? 'School url is required' : urlError)}
          error={urlPrefixError}
          {...getFieldProps('url_prefix', {
            initialValue: data ? data?.url_prefix : '',
            onChange: (e) => handleUrl(e?.target?.value?.trim()),
            rules: [{ required: true }]
          })}
          onBlur={(e) => validateUrl(e)}
        />
      </Box>
      <Box
        pad="10px"
        style={{ borderRadius: Theme.borderRadius, background: lighten(0.45, Theme.PrimaryColor) }}
      >
        <Text
          block
          color={Theme.PrimaryColor}
          style={{ borderBottom: `0.5px solid ${Theme.PrimaryColor}`, padding: '0 0 10px 0' }}
        >
          {' '}
          <span style={{ paddiing: '0 5px 0 0 ' }}>https://</span>{' '}
          <span>
            <span style={{ fontWeight: 'bold' }}>{url_prefix}</span>.safsims.com
          </span>
        </Text>
        <Spacer />
        <Text>This will be the main URL to the portal</Text>
      </Box>
      <Box pad="20px 0 10px 0">
        <Select
          placeholder="Select Country"
          elevate={true}
          name="country"
          label="Country"
          spaceTop
          spaceBottom="10px"
          required
          options={countries.map((country) => {
            return {
              label: country.name,
              value: country.country_code,
              iso3: country.iso3
            };
          })}
          style={{
            width: '100%',
            fontSize: '1em'
          }}
          error={getFieldError('country') ? 'Country is required' : null}
          {...getFieldProps('country', {
            initialValue: data ? data?.country : '',
            rules: [{ required: true }],
          })}
        />
      </Box>
      <Box pad="20px 0 10px 0">
        <Input
          label={`${schoolNameDefinition} Website`}
          placeholder="www.startopschool.com"
          block
          {...getFieldProps('school_website', {
            initialValue: data ? data?.school_website : '',
            rules: []
          })}
        />
      </Box>
      <Box align="center">
        <Button onClick={() => onSubmit()}>Next</Button>
      </Box>
    </Box>
  );
};

export default createForm()(Step1);
