import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  Notify,
  RadioButton,
  Select,
  Spacer,
  Text
} from "@flexisaf/flexibull2";
import { createForm } from "rc-form";
import React, { useState } from "react";
import styled from "styled-components";
import PhoneNumberInput from "../components/PhoneInput";
import usePasswordPolicy from "../hooks/usePasswordPolicy";
import useBreakpointValue from "../utils/useBreakpointValue/useBreakpointValue";
import { getUrlParam } from "../utils/utils";

const Error = styled.em`
  color: #ea2a8a;
  display: block;
  margin-top: 4px;
`;

const DEFAULT_ROLE = [
  { label: "School Owner", value: "School Owner" },
  { label: "Director", value: "Director" },
  { label: "Principal/Head Teacher", value: "Principal/Head Teacher" },
  { label: "ICT Admin", value: "ICT Admin" },
  { label: "Teacher", value: "Teacher" },
  { label: "Others", value: "Others" }
];

const AboutYou = ({
  form,
  setView,
  view,
  onFinish,
  loading,
  defaultCountry
}) => {
  const { getFieldError, validateFields, getFieldProps } = form;

  const [isOthers, setIsOthers] = useState(false);

  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  const initialEmail = getUrlParam("email") || "";

  const {
    handlePasswordPolicy,
    moreThan8chars,
    lowerCaseAndUppercase,
    oneNumber,
    specialCharacter,
    hasResolvedPassword
  } = usePasswordPolicy();

  const handleRoleSelect = (e) => {
    const val = e?.value;
    if (val === "Others") {
      setRole("");
      setIsOthers(true);
    } else {
      setIsOthers(false);
      setRole(e?.value);
    }
  };

  let errors;

  const handleSchoolNext = () => {
    validateFields((err, values) => {
      if (!err) {
        if (!gender) {
          return Notify("Please select a gender", { status: "error" });
        }
        if (!phone || (phone?.length || 0) < 6) {
          return Notify("Please enter a valid phone number", {
            status: "error"
          });
        }
        if (!hasResolvedPassword) {
          return Notify(
            "Please enter a valid password that matches all requirements.",
            { status: "error" }
          );
        }
        let data = {
          first_name: values.first_name,
          last_name: values.last_name,
          email_address: values.email_address,
          phone,
          gender,
          role_in_school: role || values.role,
          password: values.password
        };
        onFinish(data);
      } else {
        Notify("Please fill all fields", { status: "error" });
      }
    });
  };

  const handleViewPassword = (e) => {
    e.stopPropagation();
    setViewPassword(!viewPassword);
  };

  const width = useBreakpointValue({
    base: "auto",
    lr: "60%"
  });

  const buttonWidth = useBreakpointValue({
    base: "auto",
    md: 120
  });

  return (
    <div title="About you">
      <Box margin="0 0 0 0" width={width}>
        <Text bold block size="18px" style={{ lineHeight: "35px" }}>
          About You
        </Text>
        <Text size="14px">A bit of personal details about you</Text>
        <Spacer space="40px" />
        <Grid default="1fr 1fr" lr="1fr" sm="1fr">
          <Box>
            <Input
              placeholder="e.g John"
              label="First Name"
              spaceTop
              spaceBottom="15px"
              required
              error={
                (errors = getFieldError("first_name")
                  ? "First name is required"
                  : null)
              }
              style={{
                width: "100%"
              }}
              {...getFieldProps("first_name", {
                initialValue: "",
                rules: [{ required: true }]
              })}
            />
          </Box>
          <Box>
            <Input
              placeholder="e.g Doe"
              label="Last Name"
              spaceTop
              spaceBottom="15px"
              required
              error={
                (errors = getFieldError("last_name")
                  ? "Last name is required"
                  : null)
              }
              style={{
                width: "100%"
              }}
              {...getFieldProps("last_name", {
                initialValue: "",
                rules: [{ required: true }]
              })}
            />
          </Box>
          <Box>
            <Input
              placeholder="e.g donald@example.com"
              type="email"
              label="Email Address"
              spaceTop
              spaceBottom="15px"
              required
              error={
                (errors = getFieldError("email_address")
                  ? "Email address is required"
                  : null)
              }
              style={{
                width: "100%"
              }}
              {...getFieldProps("email_address", {
                initialValue: initialEmail,
                rules: [{ type: "email", required: true }]
              })}
            />
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <PhoneNumberInput
              width="100%"
              label="Phone Number"
              value={phone}
              name="phone number"
              onValueChange={setPhone}
              defaultCountry={defaultCountry}
              required
            />
          </Box>
        </Grid>

        <Grid
          default="1fr 1fr"
          lr="1fr"
          style={{ marginTop: "15px" }}
        >
          <Box>
            <Select
              placeholder="Select Your Role"
              elevate={true}
              name="role"
              label="role"
              spaceTop
              spaceBottom="10px"
              required
              options={DEFAULT_ROLE}
              style={{
                width: "100%",
                fontSize: "1em"
              }}
              error={
                (errors = getFieldError("role_select")
                  ? "Role is required"
                  : null)
              }
              {...getFieldProps("role_select", {
                initialValue: "",
                rules: [{ required: true }],
                onChange: (e) => handleRoleSelect(e)
              })}
            />
          </Box>
          {isOthers ? (
            <Box>
              <Input
                placeholder="e.g Admin"
                label="Enter Your Role"
                spaceTop
                spaceBottom="15px"
                required
                error={
                  (errors = getFieldError("role") ? "Role is required" : null)
                }
                style={{
                  width: "100%"
                }}
                {...getFieldProps("role", {
                  initialValue: "",
                  rules: [{ required: isOthers }]
                })}
              />
            </Box>
          ) : null}
        </Grid>

        <Grid default="1fr" lr="1fr" style={{ marginTop: "15px" }}>
          <Box>
            <Input
              placeholder="Enter your password"
              type={viewPassword ? "text" : "password"}
              label="Password"
              spaceTop
              required
              error={
                (errors = getFieldError("password")
                  ? "Password address is required"
                  : null)
              }
              style={{
                width: "100%"
              }}
              {...getFieldProps("password", {
                initialValue: "",
                type: "password",
                onChange: (e) => handlePasswordPolicy(e),
                rules: [{ required: true }]
              })}
            />
            <Box align="right" margin="2px 0">
              <Checkbox
                label="View password"
                elevate="high"
                size="15px"
                onClick={(e) => handleViewPassword(e)}
              />
            </Box>
            <Error className="error">Password must contain</Error>
            <div className="center">
              <ul>
                <li className={moreThan8chars ? "done" : ""}>
                  Minimum of 8 characters
                </li>
                <li className={lowerCaseAndUppercase ? "done" : ""}>
                  One uppercase letter and One lowercase
                </li>
                <li className={oneNumber ? "done" : ""}>One number</li>
                <li className={specialCharacter ? "done" : ""}>
                  One special character
                </li>
              </ul>
            </div>
          </Box>
          <Box margin="10px 0">
            <Text block uppercase>
              Gender{" "}
              <Text bold color="#D81E5B">
                *
              </Text>
            </Text>
            <Box margin="2px 0">
              <RadioButton
                onClick={() => setGender("MALE")}
                checked={gender === "MALE"}
                name="gender"
                label="Male"
                elevate="high"
              />
              <RadioButton
                onClick={() => setGender("FEMALE")}
                checked={gender === "FEMALE"}
                name="gender"
                label="Female"
                elevate="high"
              />
            </Box>
          </Box>
        </Grid>

        <Box pad="10px 0" align="right">
          <Button
            pale
            color="#777"
            fontColor="#777"
            iconLeft={<i className="flexibull-angle-left" />}
            spaceRight
            onClick={() => setView(view - 1)}
            height={42}
            width={buttonWidth}
          >
            Previous
          </Button>
          <Button
            iconRight={<i className="flexibull-angle-right" />}
            onClick={handleSchoolNext}
            height={42}
            width={buttonWidth}
            progress={loading}
          >
            Finish
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default createForm()(AboutYou);
