import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled, { css } from "styled-components";
import { Theme } from "../config/theme";

const Wrapper = styled.div`
  position: relative;
  & .label-wrapper {
    display: block;
    position: absolute;
    bottom: calc(100% + 8px);
    & label {
      text-transform: uppercase;
      ${(props) =>
        props.theme.noLabelUpperCase &&
        css`
          text-transform: none;
        `}
      position: relative;
      font-weight: 700;
      display: inline-block;
      font-size: 0.9em;

      &::after {
        ${(required) =>
          required &&
          css`
            content: "*";
            color: ${Theme.PrimaryRed};
            position: absolute;
            top: -2px;
            margin-left: 2px;
          `};
      }
    }

    & .desc {
      font-weight: normal;
      font-size: 0.8em;
    }
  }
  & .PhoneInput {
    background-color: #fff;
    border-radius: ${Theme.PrimaryRadius};
    border: 1px solid ${Theme.PrimaryBorderColor};
    padding: 0 12px 0 16px;
    width: ${(props) => (props.width ? props.width : "225px")};

    & .PhoneInputCountry {
      padding: 0 5px 0 0;
      border-right: 1px solid ${Theme.PrimaryBorderColor};

      & .PhoneInputCountrySelectArrow {
        margin: 0 4px 0 12px;
      }
    }

    & .PhoneInputInput {
      border: none;
      background: transparent;
      padding: 12px;
      outline: none;
      width: ${(props) => (props.width ? props.width : "225px")};
    }
  }
`;

const PhoneNumberInput = React.forwardRef((props, ref) => {
  const {
    label,
    width,
    desc,
    required,
    value,
    onValueChange,
    change,
    disabled,
    onWrapperClick,
    defaultCountry,
    name,
    ...rest
  } = props;

  return (
    <Wrapper onClick={onWrapperClick} {...props}>
      {label || desc ? (
        <div className="label-wrapper">
          {label !== undefined && <label>{label}</label>}
          {desc !== undefined && <span className="desc">{desc}</span>}
        </div>
      ) : null}
      <PhoneInput
        international
        defaultCountry={defaultCountry?.value?.toUpperCase()}
        value={value}
        onChange={(val) => {
          onValueChange?.(val);
        }}
        disabled={disabled}
        name
      />
    </Wrapper>
  );
});

export default PhoneNumberInput;
