import axios from "axios";
import config from "./config";


const { API_BASE_URL } = config;

const httpClient = axios.create({
  baseURL: API_BASE_URL,
});


export default httpClient;
