const config = {
  development: {
    API_BASE_URL: "https://api.safsims.flexisafapps-dev.com",
    SCHOOL_URL: ".safsims.flexisafapps-dev.com",
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
  staging: {
    API_BASE_URL: "https://api.safsims.flexisafapps-stage.com",
    SCHOOL_URL: ".safsims.flexisafapps-stage.com",
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
  production: {
    API_BASE_URL: "https://api.safsims.com",
    SCHOOL_URL: ".safsims.com",
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
};

export default config[process.env.REACT_APP_ENV || process.env.NODE_ENV];
