import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Grid,
  CardSelector,
  Spacer,
  Notify,
} from "@flexisaf/flexibull2";
import { Theme } from "../config/theme";
import { createForm } from "rc-form";
import axios from "axios";

const AboutSchoolSize = ({ setView, view, setAboutSchoolSize }) => {
  const [staffNumber, setStaffNumber] = useState("");
  const [studentNumber, setStudentNumber] = useState("");
  const [staffError, setStaffError] = useState("");
  const [studentError, setStudentError] = useState("");

  const handleSubmit = () => {
    if (!staffNumber.length && !studentNumber.length) {
      setStaffError("Please select staff number");
      setStudentError("Please select student number");
      Notify("Please select all fields", { status: "error" });
      return;
    } else if (!staffNumber.length) {
      setStaffError("Please select staff number");
      return;
    } else if (!studentNumber.length) {
      setStudentError("Please select student number");
      return;
    } else {
      let data = {
        staff_range: staffNumber,
        student_range: studentNumber,
      };
      setAboutSchoolSize(data);
      setView(view + 1);
    }
  };

  const handleStaffChange = (e) => {
    setStaffNumber(e.target.value);
    setStaffError("");
  };

  const handleStudentChange = (e) => {
    setStudentNumber(e.target.value);
    setStudentError("");
  };

  return (
    <div title="About your school size">
      <Box pad="0 0 0" width="60%">
        <Text bold block size="18px" style={{ lineHeight: "35px" }}>
          About your staffing
        </Text>
        <Text size="14px">
          How many staff are currently working at this institution
        </Text>
        <Spacer space="40px" />
        <Grid default="1fr 1fr">
          <CardSelector
            name="staff_range"
            value="10-30"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  10 - 30
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  staff
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStaffChange(e)}
          ></CardSelector>
          <CardSelector
            name="staff_range"
            value="40-60"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  40 - 60
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  staff
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStaffChange(e)}
          ></CardSelector>
          <CardSelector
            name="staff_range"
            value="70-90"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  70 - 90
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  staff
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStaffChange(e)}
          ></CardSelector>
          <CardSelector
            name="staff_range"
            value="100-Above"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  100 - Above
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  staff
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStaffChange(e)}
          ></CardSelector>
        </Grid>
        <Box>
          <Text
            style={{ fontStyle: "italic", textTransform: "uppercase" }}
            color={Theme.PrimaryRed}
          >
            {staffError}
          </Text>
        </Box>
      </Box>
      <Box pad="40px 0 0" width="60%">
        <Text bold block size="18px" style={{ lineHeight: "35px" }}>
          About your students
        </Text>
        <Text size="14px">
          How many students are currently enrolled at this institution
        </Text>
        <Spacer space="40px" />
        <Grid default="1fr 1fr">
          <CardSelector
            name="student_range"
            value="50-250"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  50 - 250
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  students
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStudentChange(e)}
          ></CardSelector>
          <CardSelector
            name="student_range"
            value="300-550"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  300 - 550
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  students
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStudentChange(e)}
          ></CardSelector>
          <CardSelector
            name="student_range"
            value="600-850"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  600 - 850
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  students
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStudentChange(e)}
          ></CardSelector>
          <CardSelector
            name="student_range"
            value="900-Above"
            view="custom"
            label={
              <>
                <Text block size="14px">
                  900 - Above
                </Text>
                <Text style={{ fontWeight: "400", lineHeight: "20px" }}>
                  students
                </Text>
              </>
            }
            type="radio"
            onChange={(e) => handleStudentChange(e)}
          ></CardSelector>
        </Grid>
        <Box>
          <Text
            style={{ fontStyle: "italic", textTransform: "uppercase" }}
            color={Theme.PrimaryRed}
          >
            {studentError}
          </Text>
        </Box>
        <Box pad="30px 0" align="left">
          <Button
            pale
            color="#777"
            fontColor="#777"
            iconLeft={<i className="flexibull-angle-left" />}
            spaceRight
            onClick={() => setView(view - 1)}
            height={42}
            width={120}
          >
            Previous
          </Button>

          <Button
            iconRight={<i className="flexibull-angle-right" />}
            onClick={() => handleSubmit()}
            height={42}
            width={180}
          >
            Next
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default createForm()(AboutSchoolSize);
