import React from 'react';
import { Box, Button, Modal, ModalBody, ModalClose, ModalFooter, Text } from '@flexisaf/flexibull2';

import useBreakpointValue from '../../../utils/useBreakpointValue/useBreakpointValue';
import { Theme } from '../../../config/theme';

const SafsimsPolicy = () => (
  <Box
    pad="20px"
    style={{ maxHeight: '70vh', overflowY: 'scroll', width: '100%', lineHeight: '17px' }}
  >
    <Text>
      <p>
        FlexiSAF Edusoft Limited ("FlexiSAF") is committed to protecting the privacy of our users
        ("Users") in accordance with the NDPR and GDPR regulations. This Privacy Policy ("Policy")
        explains how we collect, use, and disclose information in connection with the SAFSIMS
        platform ("Platform"), including its website, web application, and mobile applications for
        teachers and parents (collectively, "Applications").
      </p>

      <p>
        <b>1. Information We Collect</b> <br />
        We collect the following types of information from Users:
        <ul>
          <li>
            <b>School Information:</b> School name, address, contact information, and other relevant
            details provided by the School Owner during the subscription process.
          </li>
          <li>
            <b>User Information:</b> Name, email address, phone number, and other information
            provided by Users during account creation or as authorized by the School
            Owner/Administrator.
          </li>
          <li>
            <b>Student Information:</b> Name, identification number, grade level, and other
            information provided by the School Owner/Administrator with. This information may also
            include academic performance data, attendance records, and disciplinary reports, as
            authorized by the school.
          </li>
          <li>
            <b>Usage Data:</b> Information about how Users interact with the Platform, such as IP
            address, device information, browsing activity, and access times.
          </li>
        </ul>
      </p>
      <p>
        <b>2. How We Use Your Information</b>
      </p>
      <p>
        We use the information we collect for the following purposes:
        <ul>
          <li>To provide and maintain the Platform and its features.</li>
          <li>
            To communicate with Users regarding their accounts, subscriptions, and platform updates.
          </li>
          <li>
            To personalize the user experience and content based on user preferences and activity.
          </li>

          <li>
            To fulfill requests from School Owners/Administrators, such as providing student reports
            or facilitating communication between parents and teachers.
          </li>
          <li>To improve the Platform and develop new features and services.</li>
          <li>To comply with legal and regulatory requirements.</li>
        </ul>
      </p>
      <p>
        <b>3. Sharing Your Information</b>
        <br />
        We share your information with the following entities:
        <ul>
          <li>
            <b>School Owners/Administrators:</b> We share User information, including student
            information, with School Owners/Administrators who have authorized access to manage the
            Platform for their schools.
          </li>
          <li>
            <b>Service Providers:</b> We may share your information with third-party service
            providers who help us operate and maintain the Platform, such as data storage providers
            or communication platforms. These service providers are contractually obligated to keep
            your information confidential and secure.
          </li>
        </ul>
      </p>
      <p>
        <b>4. Data Retention</b>
        <br />
        We retain your information for as long as necessary to fulfill the purposes described in
        this Policy, unless a longer retention period is required or permitted by law.
      </p>

      <p>
        <b>4. Content</b>
      </p>
      <p>
        <b>5. Your Choices and Rights</b>
        <br />
        You have certain choices and rights regarding your information:
        <ul>
          <li>
            <b>Access:</b> You may request access to your personal information by contacting
            FlexiSAF.
          </li>
          <li>
            <b>Correction:</b> You may request to correct inaccurate or incomplete personal
            information.
          </li>
          <li>
            <b>Deletion:</b> You may request to delete your personal information, subject to certain
            exceptions.
          </li>
          <li>
            <b>Withdrawal of Consent:</b> You may withdraw your consent to our processing of your
            personal information at any time.
          </li>
        </ul>
      </p>
      <p>
        <b>6. Security</b>
        <br />
        We take appropriate technical and organizational measures to protect your information
        against unauthorized access, disclosure, alteration, or destruction. However, no internet
        transmission or electronic storage is completely secure, so we cannot guarantee the absolute
        security of your information.
      </p>

      <p>
        <b>8. Changes to this Policy</b>
        <br />
        We may update this Policy from time to time. We will notify you of any changes by posting
        the new Policy on the Platform and may send you an email notification (if you have provided
        us with your email address).
      </p>
      <p>
        <b>9. Contact Us</b> <br />
        If you have any questions about this Policy, please contact us at{' '}
        <a href="mailto:info@flexisaf.com">info@flexisaf.com </a> <br />
        This Privacy Policy is incorporated into and subject to the SAFSIMS Terms and Conditions.
      </p>
    </Text>
  </Box>
);

const MadrasaSimsPolicy = () => (
  <Box
    pad="20px"
    style={{ maxHeight: '70vh', overflowY: 'scroll', width: '100%', lineHeight: '17px' }}
  >
    <Text>
      <p>
        <b>Introduction</b>
        <br />
        At FlexiSAF UK, we are committed to protecting and respecting your privacy. This General
        Data Protection Regulation (GDPR) policy outlines how we collect, process, store, and
        protect your personal data, ensuring compliance with the UK GDPR and Data Protection Act
        2018. This policy applies to all data subjects whose personal data is collected by FlexiSAF
        in the UK.
      </p>

      <p>
        <b>2. Data We Collect</b> <br />
        We collect personal data for various purposes, including but not limited to:
        <ul>
          <li>
            <b>Identity Data:</b> Name, job title, and organization.
          </li>
          <li>
            <b>Contact Data:</b> Email address, phone number, and postal address
          </li>
          <li>
            <b>Technical Data:</b> IP addresses, browser type, and usage data collected via cookies
            and analytics.
          </li>
          <li>
            <b>Student Data:</b> Data required for student management within our Madrasa SIMS
            software (e.g., attendance, academic performance).
          </li>

          <li>
            <b>Financial Data:</b> Billing information, payment methods, and transaction details.
          </li>
        </ul>
        We ensure that all personal data is collected lawfully, fairly, and transparently, in
        accordance with Article 5 of the GDPR.
      </p>
      <p>
        <b>3. Lawful Basis for Processing Data</b>
        <br />
        We process personal data under the following lawful bases:
        <ul>
          <li>
            <b>Consent:</b> Where you have given explicit consent for us to process your personal
            data.
          </li>
          <li>
            <b>Contractual Obligation:</b> When processing is necessary to fulfil a contract with
            you (e.g., delivering our services such as Madrasa SIMS).
          </li>
          <li>
            <b>Legitimate Interests:</b> Processing that is in our legitimate interest and not
            overridden by your privacy rights (e.g., improving our products and services).
          </li>
          <li>
            <b>Legal Obligation:</b> When processing is necessary to comply with a legal obligation.
          </li>
        </ul>
      </p>
      <p>
        <b>4. Purpose of Data Collection</b>
        <br />
        We collect and process your personal data for the following purposes:
        <ul>
          <li>To manage customer relationships and provide our services.</li>
          <li>
            To facilitate student management and parent communication via the Madrasa SIMS software.
          </li>
          <li>To maintain accurate billing records and facilitate payment transactions.</li>
          <li>
            To send you service updates, promotional content (with your consent), and product
            improvements.
          </li>
          <li>
            To comply with legal obligations such as financial regulations, anti-money laundering
            laws, and fraud detection.
          </li>
        </ul>
      </p>
      <p>
        <b>5. Data Retention</b>
        <br />
        FlexiSAF retains personal data only for as long as is necessary to fulfill the purposes we
        collected it for, including satisfying any legal, accounting, or reporting requirements.
        <ul>
          <li>
            <b>General Data:</b> Retained for the duration of the customer relationship and up to
            six years post-termination for legal and tax purposes.
          </li>
          <li>
            <b>Student Data:</b> Retained for as long as the customer requires to fulfill their own
            legal obligations, after which it will be securely deleted.
          </li>
        </ul>
        We will perform periodic reviews of personal data to ensure that it is accurate and up to
        date, and we will delete or anonymize data that is no longer necessary.
      </p>
      <p>
        <b>6. Your Data Protection Rights</b>
        <br />
        Under UK GDPR, you have the following rights:
        <ul>
          <li>
            <b>Right to Access:</b> You can request a copy of the personal data we hold about you.
          </li>
          <li>
            <b>Right to Rectification:</b> You can ask us to correct any inaccuracies in your
            personal data.
          </li>

          <li>
            <b>Right to Erasure:</b> You can request that we delete your personal data, subject to
            legal obligations.
          </li>
          <li>
            <b>Right to Restriction of Processing:</b> You can request that we limit the way we
            process your data in certain circumstances.
          </li>

          <li>
            <b>Right to Data Portability:</b> You can request a copy of your data in a structured,
            commonly used format to transfer it to another provider.
          </li>
          <li>
            <b>Right to Object:</b> You can object to us processing your personal data based on
            legitimate interests or direct marketing purposes.
          </li>
        </ul>
        To exercise any of these rights, please contact us at madrasasims@flexisaf.com. We will
        respond to all requests within one month of receipt.
      </p>
      <p>
        <b>7. How We Protect Your Data</b>
        <br />
        We take the security of your personal data very seriously. To safeguard your data, we employ
        the following measures:
        <ul>
          <li>
            <b>Encryption:</b> Data in transit and at rest is encrypted using industry-standard
            methods.
          </li>
          <li>
            <b>Access Controls:</b> Only authorized personnel have access to your personal data,
            with strict access controls and audits in place.
          </li>

          <li>
            <b>Regular Audits:</b> We perform regular audits and security assessments to ensure our
            systems are compliant with the latest regulations and best practices.
          </li>
          <li>
            <b>Right to Restriction of Processing:</b> You can request that we limit the way we
            process your data in certain circumstances.
          </li>

          <li>
            <b>Third-Party Vendors:</b> Where we use third-party service providers (e.g., cloud
            services), we ensure they also comply with GDPR standards.
          </li>
        </ul>
      </p>

      <p>
        <b>8. Third-Party Data Sharing</b>
        <br />
        We will never sell your personal data to third parties. However, we may share your data with
        trusted third-party service providers where necessary, such as:
        <ul>
          <li>IT and system administration service providers.</li>
          <li> Legal and accounting professionals.</li>
          <li>Payment service providers.</li>
        </ul>
        All third-party data processors are required to sign data processing agreements to ensure
        they handle your data in line with UK GDPR requirements.
      </p>
      <p>
        <b>9. Data Breach Notification</b>
        <br />
        In the unlikely event of a data breach, we have protocols in place to identify, assess, and
        contain the breach as quickly as possible. If the breach is likely to result in a high risk
        to the rights and freedoms of individuals, we will notify the UK Information Commissioner’s
        Office (ICO) within 72 hours, as well as affected individuals, where required.
      </p>
      <p>
        <b>10. International Data Transfers</b>
        <br />
        If we transfer personal data outside the UK or EEA (European Economic Area), we ensure that
        the recipient country has adequate data protection laws or that appropriate safeguards, such
        as Standard Contractual Clauses (SCCs), are in place.
      </p>
      <p>
        <b>11. Cookies and Tracking</b>
        <br />
        Our website uses cookies and similar technologies to enhance user experience, analyse usage
        data, and tailor content.
      </p>

      <p>
        <b>12. Children's Privacy</b>
        <br />
        We recognise the importance of protecting children's personal data. Our software is designed
        for use by madrasahs and educational institutions, and any collection of children's data is
        under the control of these institutions. We require that schools or guardians provide
        consent where applicable.
      </p>

      <p>
        <b>13. Updates to This Policy</b>
        <br />
        We may update this policy from time to time to reflect changes in regulations, best
        practices, or our business operations. All updates will be posted on our website, and
        significant changes will be communicated to data subjects directly.
      </p>
      <p>
        <b>14. Contact Information</b>
        <br />
        If you have any questions about this GDPR policy or wish to exercise any of your data
        protection rights, please contact our Data Protection Officer at:
        <br />
        Email: <a href="mailto:madrasasims@flexisaf.com">madrasasims@flexisaf.com</a> <br />
        Postal Address: 167-169 5th Floor Great Portland Street, London, England, W1W 5PF.
        <br />
        If you feel that your data protection rights have been breached, you have the right to lodge
        a complaint with the UK Information Commissioner’s Office (ICO) at www.ico.org.uk.
      </p>
    </Text>
  </Box>
);

export const PrivacyPolicy = ({ open, onClose, isMadrasa }) => {
  const width = useBreakpointValue({ base: '90%', md: '50vw' });
  return (
    <Modal onClose={onClose} open={open}>
      <ModalBody width={width}>
        <ModalClose onClick={onClose}>&times;</ModalClose>
        <Box pad="20px">
          <Text bold size="20px">
            Privacy Policy
          </Text>
        </Box>
        {isMadrasa ? <MadrasaSimsPolicy /> : <SafsimsPolicy />}
        <ModalFooter>
          <Box pad="20px" style={{ display: 'flex' }}>
            <Button
              style={{ margin: '0 0 0 auto' }}
              onClick={onClose}
              plain
              fontColor={Theme.PrimaryFontColor}
            >
              Close
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
