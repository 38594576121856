import React from "react";
import { Notify } from "@flexisaf/flexibull2";
import httpClient from "../utils/http-client";
import useLoading from "../utils/useLoading/useLoading";

const useVerifyOtp = ({ callback, url_prefix }) => {
  const { loading, startLoading, stopLoading } = useLoading();

  const headers = {
    "X-TENANT-ID": url_prefix,
  };
  const verifyOtp = async ({ token, user_id }) => {
    startLoading();
    try {
      await httpClient.post("/auth/confirm-user", { token, user_id }, {
        headers
      });
      stopLoading();
      Notify("Account verified successfully.")
      callback()
    } catch (error) {
      stopLoading();
      Notify("An error occurred. Please check the code.", { status: "error" });
    }
  };

  return {
    verifyOtp,
    loading,
  };
};

export default useVerifyOtp;
