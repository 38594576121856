import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, Button, Grid, Spacer, Select, Notify, Input } from '@flexisaf/flexibull2';
import { Theme } from '../config/theme';
import { createForm } from 'rc-form';
import { lighten } from 'polished';
import httpClient from '../utils/http-client';
import config from '../utils/config';
import useBreakpointValue from '../utils/useBreakpointValue/useBreakpointValue';
import useValidateSchool from '../hooks/useValidateSchool';
import { onlyAlphabet } from '../utils/utils';
import { PrimaryInput } from '../components/PrimaryInput';
import { pushEvent } from "../utils/utils";

const CURRENCY_OPTIONS = [
  { value: 'NGN', name: 'Naira', sign: '₦', label: 'Naira (₦)' },
  { value: 'USD', name: 'US Dollar', sign: '$', label: 'US Dollar ($)' },
  { value: 'EUR', name: 'Euro', sign: '€', label: 'Euro (€)' },
  { value: 'GBP', name: 'Pound', sign: '£', label: 'Pound (£)' }
];

const AboutTheSchool = ({ form, setView, view, setAboutSchool, setDefaultCountry }) => {
  const { getFieldError, validateFields, getFieldProps, getFieldValue, setFieldsValue } = form;

  const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  const [urlError, setUrlError] = useState('');
  const [error, setError] = useState(null);
  const [urls, setUrls] = useState([]);
  const [schoolUrl, setSchoolUrl] = useState('');

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await httpClient.get(`/countries`);
        setCountries(response.data);
      } catch (error) {
        setError(error);
        return;
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchUrlPrefixes = async () => {
      try {
        const response = await httpClient.get(`/signup/url-prefixes`);
        setUrls(response.data.url_prefixes);
      } catch (error) {
        setError(error);
        return;
      }
    };
    fetchUrlPrefixes();
  }, []);

  const { schoolExists, setSchoolExists, validateSchool } = useValidateSchool({
    tenantId: schoolUrl
  });

  // const fetchStates = async (e) => {
  //   await httpClient
  //     .get(`countries/${e}/states`)
  //     .then((response) => {
  //       setStates(response.data);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     });
  // };

  // const handleState = (e) => {
  //   fetchStates(e);
  //   if (e !== "ng") {
  //     if (getFieldValue("state").value) {
  //       setFieldsValue({ state: { label: "", value: "", country_code: "" } });
  //     }
  //   }
  // };

  const [initialPrefix, setInitialPrefix] = useState('');

  let format = /[ `§±!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

  const handleSchoolNext = () => {
    validateFields((err, values) => {
      if (!err) {
        let data = {
          school_name: values.school_name,
          short_name: values.short_name.toLowerCase(),
          website: values.school_website,
          country: {
            country_code: values.country.value,
            iso3: values.country.iso3,
            name: values.country.label
          },
          url_prefix: values.short_name.toLowerCase()
        };

        // if (values.state.length === 0) {
        //   delete data.state;
        // }
        if (format.test(getFieldValue('short_name'))) {
          Notify(
            "School short name must contain only numbers, letters and underscores",
            {
              status: "error",
            }
          );
          return;
        }
        if (
          urls.length > 0 &&
          urls.find((url) => url.toLowerCase() === getFieldValue('short_name'))
        ) {
          Notify("This School short name entered already exists", {
            status: "error",
          });
          return;
        }

        if (schoolExists) {
          Notify('The school url entered already exists', {
            status: 'error'
          });
          return;
        }
        setAboutSchool(data);
        pushEvent("New_School_Signup_Started", {
          user_id: data.school_name,
          organisation: data.url_prefix,
          user_role: "ONBOARDING",
        });
        setView(view + 1);
      } else {
        Notify('Please fill all fields', { status: 'error' });
      }
    });
  };

  const handleCountrySelect = (e) => {
    if (e?.value === 'ng') {
      setFieldsValue({ currency: CURRENCY_OPTIONS[0] });
    }
    setDefaultCountry(e);
  };
  let errors;

  const handleUrl = (e) => {
    if (urls.length > 0 && urls.find((url) => url.toLowerCase() === getFieldValue('short_name'))) {
      setUrlError('This school short name already exists');
    } else if (format.test(e)) {
      setUrlError(
        "School short name must contain only numbers, letters and underscores"
      );
    } else {
      setUrlError('');
      setSchoolExists(false);
    }
  };

  const validateUrl = (e) => {
    e.stopPropagation();
    setSchoolUrl(e.target.value);
    validateSchool(getFieldValue('url_prefix'));
    if (schoolExists) {
      setUrlError('This School short name already exists');
    } else {
      setUrlError('');
    }
  };

  useEffect(() => {
    if (schoolExists) {
      setUrlError("This School short name already exists");
    } else {
      setUrlError('');
    }
  }, [schoolExists]);

  const width = useBreakpointValue({
    base: 'auto',
    lr: '60%'
  });

  return (
    <div title="About the school">
      <Box pad="0 0 0" margin="0 0 0 0" width={width}>
        <Text bold block size="18px" style={{ lineHeight: '35px' }}>
          About The School
        </Text>
        <Text size="14px">A bit of information about the school you work and represent</Text>
        <Spacer space="40px" />
        <form>
          <Grid default="1fr" lr="1fr" sm="1fr">
            <Box>
              <PrimaryInput
                placeholder="e.g Startop schools"
                label="School Name"
                name="schoolName"
                type="text"
                spaceTop
                spaceBottom="10px"
                required
                error={(errors = getFieldError('school_name') ? 'School name is required' : null)}
                style={{
                  width: '100%'
                }}
                {...getFieldProps('school_name', {
                  initialValue: '',
                  rules: [{ required: true }]
                })}
              />
            </Box>
            <Grid default="1fr 1fr" lr="1fr" sm="1fr">
              <Input
                placeholder="e.g StarTop"
                label="School Short Name"
                name="short_name"
                type="text"
                spaceTop
                spaceBottom="10px"
                required
                value={initialPrefix}
                onKeyUp={onlyAlphabet}
                error={
                  (errors = getFieldError('short_name') ? 'School short name is required' : null)
                }
                style={{
                  width: '100%',
                }}
                {...getFieldProps('short_name', {
                  onChange: (e) => {
                    const alphabets = /[^A-Za-z]+$/;
                    e.target.value = e.target.value.replace(alphabets, "");
                    setInitialPrefix(e.target.value);
                    handleUrl(e.target.value);
                  },
                  initialValue: '',
                  rules: [{ required: true }]
                })}
                onBlur={(e) => validateUrl(e)}
              />

              <Select
                placeholder="Select Country"
                elevate={true}
                name="country"
                label="Country"
                spaceTop
                spaceBottom="10px"
                required
                options={countries.map((country) => {
                  return {
                    label: country.name,
                    value: country.country_code,
                    iso3: country.iso3
                  };
                })}
                style={{
                  width: '100%',
                  fontSize: '1em'
                }}
                error={(errors = getFieldError('country') ? 'Country is required' : null)}
                {...getFieldProps('country', {
                  initialValue: '',
                  rules: [{ required: true }],
                  onChange: (e) => handleCountrySelect(e)
                })}
              />
            </Grid>
            <Grid default="3fr 1fr" lr="1fr" sm="1fr" style={{ display: 'none' }}>
              <PrimaryInput
                placeholder="e.g Startop schools"
                label="School URL"
                name="url_prefix"
                type="url"
                spaceTop
                spaceBottom="20px"
                value={initialPrefix}
                required
                error={(errors = getFieldError('url_prefix') ? 'School url is required' : urlError)}
                style={{
                  width: '100%'
                }}
                {...getFieldProps('url_prefix', {
                  onChange: (e) => handleUrl(e.target.value),
                  initialValue: initialPrefix,
                  value: initialPrefix,
                  rules: [{ required: true }]
                })}
                onBlur={(e) => validateUrl(e)}
              />
              <div
                style={{
                  borderRadius: '0 3px 3px 0',
                  margin: '20px 0 10px -15px',
                  height: '42px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#F7F9FC',
                  border: '1px solid #D8D8E3',
                  zIndex: '1'
                }}
              >
                <Text color="#7A8599">{config.SCHOOL_URL}</Text>
              </div>
            </Grid>

            <Box
              round
              name="url_prefix"
              background={lighten(0.48, '#0066F5')}
              style={{ border: '1px solid #0066F5' }}
            >
              <Grid default="max-content auto" sm="max-content auto">
                <Box background="#0066F5" pad="0 10px" vAlign>
                  <Text color="#FFF" size="14px">
                    <i className="saf-link-1" />
                  </Text>
                </Box>
                <Box pad="10px">
                  <Text color="#0066F5">https://</Text>
                  <Text bold color="#0066F5" style={{ margin: '0 0' }}>
                    {initialPrefix.toLowerCase()}
                  </Text>
                  <Text color="#0066F5">{config.SCHOOL_URL}</Text>
                </Box>
              </Grid>
            </Box>

            <Text style={{ marginTop: '-8px' }}>This will be the main url link to your portal</Text>
            <Box>
              <Text
                style={{
                  fontStyle: 'italic',
                  textTransform: 'capitalize',
                  fontSize: '10px'
                }}
                color={Theme.PrimaryRed}
              >
                {urlError ? urlError : errors}
              </Text>
            </Box>
            <Box>
              <PrimaryInput
                placeholder="e.g www.startopschools.com"
                label="School Website"
                name="school_website"
                type="text"
                spaceTop
                spaceBottom="20px"
                style={{
                  width: '100%'
                }}
                {...getFieldProps('school_website', {
                  initialValue: ''
                })}
              />
            </Box>
          </Grid>
          {/* <Grid default="1fr 1fr">
          <Select
            placeholder="Select Country"
            elevate={true}
            name="country"
            label="Country"
            spaceTop
            options={countries.map((country) => {
              return {
                label: country.name,
                value: country.country_code,
                iso3: country.iso3,
              };
            })}
            style={{
              width: "100%",
              fontSize: "1em",
            }}
            error={
              (errors = getFieldError("country") ? "Country is required" : null)
            }
            {...getFieldProps("country", {
              onChange: (e) => handleState(e.value),
              initialValue: "",
              rules: [{ required: true }],
            })}
          />
          <Select
            placeholder="Select State"
            elevate={true}
            name="state"
            label="State"
            spaceTop
            options={states.map((state) => {
              return {
                label: state.state_name,
                value: state.state_id,
                country_code: state.country.country_code,
              };
            })}
            style={{
              width: "100%",
              fontSize: "1em",
            }}
            {...getFieldProps("state", {
              initialValue: "",
              rules: [{ required: false }],
            })}
          />
        </Grid> */}
          <Box pad="30px 0" align="right">
            {/* <Button
            pale
            color="#777"
            fontColor="#777"
            iconLeft={<i className="flexibull-angle-left" />}
            spaceRight
            onClick={() => setView(view - 1)}
            height={42}
            width={120}
          >
            Previous
          </Button> */}

            <Button
              iconRight={<i className="flexibull-angle-right" />}
              onClick={handleSchoolNext}
              height={42}
              width={120}
              disabled={schoolExists}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default createForm()(AboutTheSchool);
