import { useWindowSize } from "../useWindowSize/useWindowSize";


const breakpoints = {
  base: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1536,
};

const useBreakpointValue = (props) => {
  const { base, md, lg, xl, xxl } = props;
  const size = useWindowSize();
  const width = size.width || 0;
  let value;
  if (base) {
    value = base;
  }
  if (md && width >= breakpoints.md) {
    value = md;
  }
  if (lg && width >= breakpoints.lg) {
    value = lg;
  }
  if (xl && width >= breakpoints.xl) {
    value = xl;
  }
  if (xxl && width >= breakpoints.xxl) {
    value = xxl;
  }
  return value;
};

export default useBreakpointValue;
